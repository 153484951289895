


































































































import { reqArticleList } from "@/api";
import { NewsColumns } from "@/types/enum";
import { readNewsDetailHandler, transformNewsString } from "@/utils";
import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "Putting"
})
export default class extends Vue {
  news: any[] = [];
  loading = false;
  page = 1;
  size = 10;
  videos: any[] = [];

  get paginationNews() {
    const { page, size } = this;
    return this.news.slice((page - 1) * size, page * size);
  }

  readDetailHandler(item: any) {
    readNewsDetailHandler(item);
  }

  async initList() {
    const { data: list } = await reqArticleList(NewsColumns.ANTITRIADPUTTING);
    const { data: list1 } = await reqArticleList(
      NewsColumns.COMPREHENSIVEANTICHEATVIDEO
    );
    this.news = transformNewsString(list);
    this.videos = transformNewsString(list1);
  }

  read = readNewsDetailHandler;

  created() {
    this.initList();
  }
}
